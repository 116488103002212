import CalculationStep1 from "./CalculationStep1";
import CalculationStep1DxfConfig from "./CalculationStep1DxfConfig";
import CalculationStep1Plastx from "./CalculationStep1Plastx";

interface DefaultProps {
  onChange: any;
  onCuttingTypeChange: any;
  onGenerateDxf: any;
}

export default function CalculationStep1Switcher(props: DefaultProps) {
  const { onChange, onCuttingTypeChange, onGenerateDxf } = props;

  const RenderSwitch = () => {
    if (process.env.REACT_APP_PLASTICS_CONFIGURATOR == "true") {
      return (
        <CalculationStep1Plastx
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      );
    } else if (process.env.REACT_APP_ONLY_DXF_CONFIGURATOR == "true") {
      return (
        <CalculationStep1DxfConfig
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      );
    } else {
      return (
        <CalculationStep1
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      );
    }
  };

  return <>{RenderSwitch()}</>;
}

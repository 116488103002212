/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { ShopType } from "app/models/ShopType";
import { configSelector } from "app/redux/slides/config.slide";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CuttingType } from "../../models/CuttingType";
import { MaterialGroup } from "../../models/MaterialGroup";
import { Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { Project } from "../../models/Project";
import { materialSelector } from "../../redux/slides/material.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import CadFileItemMilledAndTurned from "./CadFileItemMilledAndTurned";
import CadFileItemSheetMetal from "./CadFileItemSheetMetal";
import "./cadFileItem.module.scss";
import CadFileItemTechnicalPlastics from "./CadFileItemTechnicalPlastics";
import CadFileItemSheetMetalPublic from "./CadFileItemSheetMetalPublic";
import { constant } from "lodash";
interface DefaultProps extends HTMLAttributes<any> {
  file?: UploadFile<any>;
  pdfFile?: RcFile;
  type?: CuttingType;
  part?: Part;
  mode?: PartViewMode;
  pricePreview?: number;
  pricePreviewLoading?: boolean;
  onChange?: any;
  onQuantityChange?: any;
  onPartUploadedChange?: any;
  onPartRender?: any;
}

const sheetMetalType: any = [
  CuttingType.SHEET_METAL_DWG,
  CuttingType.SHEET_METAL_DXF,
  CuttingType.SHEET_METAL_STP,
  CuttingType.SHEET_METAL_CONFIGURE,
];

function CadFileItem(props: DefaultProps) {
  // const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const { data } = useSelector(projectSelector);
  const {
    type,
    file,
    pdfFile,
    mode = PartViewMode.EDIT,
    pricePreview,
    pricePreviewLoading,
    onChange,
    onQuantityChange,
    onPartUploadedChange,
    onPartRender,
    part,
    ...viewProps
  } = props;

  let dataCuttingType =
    props.type ||
    (config.shopType === ShopType.BOTH ||
    config.shopType === ShopType.MILLING_AND_TURNING
      ? CuttingType.MILLING_AND_TURNING
      : CuttingType.SHEET_METAL);

  if (process.env.REACT_APP_PLASTICS_CONFIGURATOR === "true") {
    if (
      file?.name?.toLowerCase().includes("step") ||
      file?.name?.toLowerCase().includes("stp")
    ) {
      dataCuttingType = CuttingType.MILLING_AND_TURNING;
    }
  }

  const [cuttingType] = useState(dataCuttingType);
  const { groups, sheetMetalGroups } = useSelector(materialSelector);
  const materialGroups: MaterialGroup[] =
    cuttingType === CuttingType.MILLING_AND_TURNING ? groups : sheetMetalGroups;

  const [project] = useState<Project>(data);
  const [viewMode, setViewMode] = useState<PartViewMode>(
    mode ||
      ((!!file || (part && part.totalPrice === null && part.auto)) &&
        PartViewMode.EDIT) ||
      (part &&
        (part.totalPrice !== null || !part?.auto) &&
        (project?.selfCalculation
          ? PartViewMode.ADAPT
          : PartViewMode.UPDATED)) ||
      PartViewMode.EDIT
  );

  useEffect(() => {
    if (part) {
      dispatch(projectActions.setPartStatus({ id: part.id, data: viewMode }));
    }
  }, [viewMode]);

  useEffect(() => {
    if (project?.archived == true) {
      setViewMode(PartViewMode.READ_ONLY);
    }
  }, [project?.archived]);

  const isCuttingTypeSheetMetal =
    cuttingType === CuttingType.SHEET_METAL ||
    cuttingType === CuttingType.SHEET_METAL_STP ||
    cuttingType === CuttingType.SHEET_METAL_DXF ||
    cuttingType === CuttingType.SHEET_METAL_DWG ||
    part?.cuttingType === CuttingType.SHEET_METAL_STP ||
    part?.cuttingType === CuttingType.SHEET_METAL_DXF ||
    part?.cuttingType === CuttingType.SHEET_METAL_DWG;

  const isCuttingTypeMilling =
    cuttingType === CuttingType.MILLING_AND_TURNING ||
    part?.cuttingType === CuttingType.MILLING_AND_TURNING;

  const isTechnicalPlastics = !!process.env.REACT_APP_TECHNICAL_PLASTICS;

  const isSheetMetalConfigurate =
    part?.cuttingType === CuttingType.SHEET_METAL_CONFIGURE;
  const isSheetMetalConfigurateMiniTemplate =
    process.env.REACT_APP_DXF_CONFIG_MINI_UI == "true";

  const isSheetMetal =
    sheetMetalType.includes(cuttingType) ||
    sheetMetalType.includes(part?.cuttingType);

  const RenderCadFile = () => {
    if (isSheetMetalConfigurate && isSheetMetalConfigurateMiniTemplate) {
      return (
        <CadFileItemSheetMetalPublic
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={onPartUploadedChange}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
        />
      )
    } else if ((isCuttingTypeSheetMetal || (isSheetMetalConfigurate && !isSheetMetalConfigurateMiniTemplate))) {
      return (
        <CadFileItemSheetMetal
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={onPartUploadedChange}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
        />
      )
    } else if (isCuttingTypeMilling && !isTechnicalPlastics && !isSheetMetal) {
      return (
        <CadFileItemMilledAndTurned
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={onPartUploadedChange}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
        />
      )
    } else if (isCuttingTypeMilling && isTechnicalPlastics && !isSheetMetal) {
        return (
          <CadFileItemTechnicalPlastics
          {...viewProps}
          file={file}
          pdfFile={pdfFile}
          type={type}
          part={part}
          mode={mode}
          pricePreview={pricePreview}
          pricePreviewLoading={pricePreviewLoading}
          onChange={onChange}
          onQuantityChange={onQuantityChange}
          onPartUploadedChange={onPartUploadedChange}
          onPartRender={onPartRender}
          materialGroups={materialGroups}
          dataCuttingType={dataCuttingType}
        />
        )
    }
  }

  return (
    <>{RenderCadFile()}</>
  );
}

export default CadFileItem;
